import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export function checkError(err) {
    // if (err.response.status === 401) {
    //     sessionStorage.removeItem('adminToken');
    //     sessionStorage.removeItem('loggedAdmin');
    //     window.location.href = '/login';
    //     toast.error("Token is expeired.", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         theme: "colored"
    //     });
    //     window.location.reload();
    // } else if (err.response.status === 500) {
    //     // window.location.href = process.env.REACT_APP_WEB_URL + "/#/error-500";
    // }

    toast.error(err.response.data.message);
}

export const apiTimeout = (promise, t = 30000) => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error('Request timed out'));
        }, t);

        promise
            .then((response) => {
                clearTimeout(timeoutId);
                resolve(response);
            })
            .catch((error) => {
                clearTimeout(timeoutId);
                reject(error);
            });
    });
};