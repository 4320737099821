export const CREATE_ASPIRATION = "CREATE_ASPIRATION";
export const CREATE_ASPIRATION_FAILURE = "CREATE_ASPIRATION_FAILURE";
export const CREATE_ASPIRATION_SUCCESS = "CREATE_ASPIRATION_SUCCESS";

export const DELETE_ASPIRATION = "DELETE_ASPIRATION";
export const DELETE_ASPIRATION_FAILURE = "DELETE_ASPIRATION_FAILURE";
export const DELETE_ASPIRATION_SUCCESS = "DELETE_ASPIRATION_SUCCESS";

export const GET_ASPIRATION = "GET_ASPIRATION";
export const GET_ASPIRATION_FAILURE = "GET_ASPIRATION_FAILURE";
export const GET_ASPIRATION_SUCCESS = "GET_ASPIRATION_SUCCESS";

export const GET_ASPIRATION_BY_ID = "GET_ASPIRATION_BY_ID";
export const GET_ASPIRATION_BY_ID_FAILURE = "GET_ASPIRATION_BY_ID_FAILURE";
export const GET_ASPIRATION_BY_ID_SUCCESS = "GET_ASPIRATION_BY_ID_SUCCESS";

export const UPDATE_ASPIRATION = "UPDATE_ASPIRATION";
export const UPDATE_ASPIRATION_FAILURE = "UPDATE_ASPIRATION_FAILURE";
export const UPDATE_ASPIRATION_SUCCESS = "UPDATE_ASPIRATION_SUCCESS";
