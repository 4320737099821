import *  as ActionTypes from "./issueActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    issues: [],
    count:0
};



export const issueReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_ISSUE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_ISSUE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_ISSUE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_ISSUE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_ISSUE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_ISSUE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_ISSUE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_ISSUE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_ISSUE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                issues: action?.payload?.data?.data || [],
                count: action?.payload?.data?.count || 0
            }
        case ActionTypes.UPDATE_ISSUE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_ISSUE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_ISSUE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}