export const CREATE_ASPIRE = "CREATE_ASPIRE";
export const CREATE_ASPIRE_FAILURE = "CREATE_ASPIRE_FAILURE";
export const CREATE_ASPIRE_SUCCESS = "CREATE_ASPIRE_SUCCESS";

export const DELETE_ASPIRE = "DELETE_ASPIRE";
export const DELETE_ASPIRE_FAILURE = "DELETE_ASPIRE_FAILURE";
export const DELETE_ASPIRE_SUCCESS = "DELETE_ASPIRE_SUCCESS";

export const GET_ASPIRE = "GET_ASPIRE";
export const GET_ASPIRE_FAILURE = "GET_ASPIRE_FAILURE";
export const GET_ASPIRE_SUCCESS = "GET_ASPIRE_SUCCESS";

export const GET_ASPIRE_BY_ID = "GET_ASPIRE_BY_ID";
export const GET_ASPIRE_BY_ID_FAILURE = "GET_ASPIRE_BY_ID_FAILURE";
export const GET_ASPIRE_BY_ID_SUCCESS = "GET_ASPIRE_BY_ID_SUCCESS";

export const UPDATE_ASPIRE = "UPDATE_ASPIRE";
export const UPDATE_ASPIRE_FAILURE = "UPDATE_ASPIRE_FAILURE";
export const UPDATE_ASPIRE_SUCCESS = "UPDATE_ASPIRE_SUCCESS";
