export const CREATE_CONTENT = "CREATE_CONTENT";
export const CREATE_CONTENT_FAILURE = "CREATE_CONTENT_FAILURE";
export const CREATE_CONTENT_SUCCESS = "CREATE_CONTENT_SUCCESS";

export const GET_CONTENT = "GET_CONTENT";
export const GET_CONTENT_FAILURE = "GET_CONTENT_FAILURE";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";

export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENT_FAILURE = "UPDATE_CONTENT_FAILURE";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";

export const DELETE_CONTENT = "DELETE_CONTENT";
export const DELETE_CONTENT_FAILURE = "DELETE_CONTENT_FAILURE";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";



 



