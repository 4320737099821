import '../App.css';
import AppRootContainer from '../shared/rootContainer';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { Provider } from "react-redux";
import store from "./store";
import { CssBaseline, Box } from "@mui/material";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  return (

    <Box>
      <ToastContainer style={{ zIndex: '99996' }} />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <BrowserRouter>
            <AppRootContainer />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </Box>
  );
}

export default App;


