import RootComponent from "./rootComponent";
import { connect } from "react-redux";
import { getAdminById } from "../api/admin/adminAction";

const mapDispatchToProps = {
    getAdminById
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.adminPage.isLoggedIn,
    addminById: state.adminPage.addminById,
})



export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);