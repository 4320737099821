import *  as ActionTypes from "./grievancesActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    grievances: [],
    grievanceById:[],
    count:0,
};



export const grievancesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_GRIEVANCES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_GRIEVANCES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_GRIEVANCES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_GRIEVANCES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_GRIEVANCES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_GRIEVANCES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_GRIEVANCES:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_GRIEVANCES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_GRIEVANCES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                grievances: action?.payload?.data?.data || [],
                count: action?.payload?.data?.count || 0,
            }
        case ActionTypes.GET_GRIEVANCES_BY_ID:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_GRIEVANCES_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_GRIEVANCES_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                grievanceById: action?.payload?.data || []
            }
        case ActionTypes.UPDATE_GRIEVANCES:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_GRIEVANCES_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_GRIEVANCES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}