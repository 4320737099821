import * as actionType from "./contentCategoryActionType";

const initialState = {
    isLoading: false,
    isSaved: false,
    contentCategoryes: [],
    count:0
};




export const contentCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.CREATE_CONTENT_CATEGORY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.CREATE_CONTENT_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.CREATE_CONTENT_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        case actionType.GET_CONTENT_CATEGORY:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.GET_CONTENT_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_CONTENT_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contentCategoryes: action?.payload?.data?.data || [],
                count: action?.payload?.data?.count || [],
            };
        case actionType.UPDATE_CONTENT_CATEGORY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.UPDATE_CONTENT_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.UPDATE_CONTENT_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        case actionType.DELETE_CONTENT_CATEGORY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            };
        case actionType.DELETE_CONTENT_CATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.DELETE_CONTENT_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            };
        default: 
            return state;
    }
}