import * as actionType from "./adminActionType";

const initialState = {
    isLoading: false,
    isSaved: false,
    isLoggedIn: sessionStorage.getItem('loggedAdmin') !== null ? true : false,
    loggedAdmin: sessionStorage.getItem('loggedAdmin') !== null ? JSON.parse(sessionStorage.getItem('loggedAdmin')) : {},
    addminById: [],
    admins:[]
};




export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADMIN_LOGIN:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userDetails: action.payload ? action.payload.data : {},
                isLoggedIn: sessionStorage.getItem('loggedAdmin') !== null ? true : false,
            };
        case actionType.GET_ADMIN_BY_ID:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.GET_ADMIN_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ADMIN_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                addminById: action.payload ? action.payload.data : {},
            }
        case actionType.UPDATE_ADMIN:
            return {
                ...state,
                isLoading: true,
                isSaved:false,
            };
        case actionType.UPDATE_ADMIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isSaved: false,

            };
        case actionType.UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
                userDetails: action.payload ? action.payload.data : {},
            };
        case actionType.GET_ADMIN:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.GET_ADMIN_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionType.GET_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                admins: action.payload ? action.payload.data : {},
            }
        case actionType.CREATE_ADMIN:
            return {
                ...state,
                isLoading: true,
                isSaved:false,
            };
        case actionType.CREATE_ADMIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isSaved:false,
            };
        case actionType.CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved:true
            }
        case actionType.DELETE_ADMIN:
            return {
                ...state,
                isLoading: true,
                isSaved:false,
            };
        case actionType.DELETE_ADMIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isSaved:false,
            };
        case actionType.DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved:true
            }
        default:
            return state;
    }
}