import * as actionTypes from "./adminActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
import { toast } from "react-toastify";


const { REACT_APP_API_URL } = process.env;

export const adminLogin = (data) => {
    return (dispatch) => {
        dispatch(adminLoginInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/admin/authenticate`, data))
            .then((response) => {
                setLoginSession(response.data.data);
                dispatch(adminLoginSuccess(response.data.data));
                toast.success("Loggin successfully", {
                    theme: "colored",
                    autoClose: 3000
                });
            })
            .catch(function (error) {
                dispatch(adminLoginFailure());
                checkError(error);
            })
    };
};


export const adminLoginInit = () => ({
    type: actionTypes.ADMIN_LOGIN
});

export const adminLoginFailure = () => ({
    type: actionTypes.ADMIN_LOGIN_FAILURE
});

export const adminLoginSuccess = data => ({
    type: actionTypes.ADMIN_LOGIN_SUCCESS,
    payload: { data }
});







// get by id

export const getAdminById = (id) => {
    return (dispatch) => {
        dispatch(adminByIInit());
        apiTimeout(axios.get(`${REACT_APP_API_URL}/admin/${id}`))
            .then((response) => {
                dispatch(adminByISuccess(response.data.data));
            })
            .catch(function (error) {
                dispatch(adminByIFailure());
                checkError(error);
            })
    };
};


export const adminByIInit = () => ({
    type: actionTypes.GET_ADMIN_BY_ID
});

export const adminByIFailure = () => ({
    type: actionTypes.GET_ADMIN_BY_ID_FAILURE
});

export const adminByISuccess = data => ({
    type: actionTypes.GET_ADMIN_BY_ID_SUCCESS,
    payload: { data }
});

// update admin

export const updateAdminLogin = (data) => {
    let params= new FormData();

    Object.keys(data).forEach((key)=>{
        let value = data[key];
        params.append(key,value);
    })


    return (dispatch) => {
        dispatch(adminLoginInit());
        apiTimeout(axios.put(`${REACT_APP_API_URL}/admin/${data._id}`, params))
            .then((response) => {
                dispatch(updateAdminLoginSuccess(response.data.data));
                toast.success("Update Admin successfully", {
                    theme: "colored",
                    autoClose: 3000
                });
                setLoginSession(data,'update')
            })
            .catch(function (error) {
                dispatch(updateAdminLoginFailure());
                checkError(error);
            })
    };
};


export const updateAdminLoginInit = () => ({
    type: actionTypes.UPDATE_ADMIN
});

export const updateAdminLoginFailure = () => ({
    type: actionTypes.UPDATE_ADMIN_FAILURE
});

export const updateAdminLoginSuccess = data => ({
    type: actionTypes.UPDATE_ADMIN_SUCCESS,
    payload: { data }
});


//   ------:  SET TOKEN :-------

function setLoginSession(data, update) {
    if (update === 'update') {
        sessionStorage.setItem("loggedAdmin", JSON.stringify(data));
    } else {
        sessionStorage.setItem("loggedAdmin", JSON.stringify(data));
        sessionStorage.setItem("adminToken", data?.token);
        sessionStorage.setItem("adminId", data?._id);
    }
}


// get all admin

export const getAdmin = () => {
    return (dispatch) => {
        dispatch(getAdminInit());
        apiTimeout(axios.get(`${REACT_APP_API_URL}/admin`))
            .then((response) => {
                dispatch(getAdminSuccess(response.data.data));
            })
            .catch(function (error) {
                dispatch(getAdminFailure());
                checkError(error);
            })
    };
};


export const getAdminInit = () => ({
    type: actionTypes.GET_ADMIN
});

export const getAdminFailure = () => ({
    type: actionTypes.GET_ADMIN_FAILURE
});

export const getAdminSuccess = data => ({
    type: actionTypes.GET_ADMIN_SUCCESS,
    payload: { data }
});



 


// create admin

export const createAdminLogin = (data) => {
    return (dispatch) => {
        dispatch(adminLoginInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/admin/register`, data))
            .then((response) => {
                dispatch(createAdminLoginSuccess(response.data.data));
                toast.success("Created Admin successfully", {
                    theme: "colored",
                    autoClose: 3000
                });
            })
            .catch(function (error) {
                dispatch(createAdminLoginFailure());
                checkError(error);
            })
    };
};


export const createAdminLoginInit = () => ({
    type: actionTypes.CREATE_ADMIN
});

export const createAdminLoginFailure = () => ({
    type: actionTypes.CREATE_ADMIN_FAILURE
});

export const createAdminLoginSuccess = data => ({
    type: actionTypes.CREATE_ADMIN_SUCCESS,
    payload: { data }
});





// delete admin

export const deleteAdminLogin = (id) => {
    return (dispatch) => {
        dispatch(deleteAdminLoginInit());
        apiTimeout(axios.delete(`${REACT_APP_API_URL}/admin/${id}`))
            .then((response) => {
                dispatch(deleteAdminLoginSuccess(response.data.data));
            })
            .catch(function (error) {
                dispatch(deleteAdminLoginFailure());
                checkError(error);
            })
    };
};


export const deleteAdminLoginInit = () => ({
    type: actionTypes.DELETE_ADMIN
});

export const deleteAdminLoginFailure = () => ({
    type: actionTypes.DELETE_ADMIN_FAILURE
});

export const deleteAdminLoginSuccess = data => ({
    type: actionTypes.DELETE_ADMIN_SUCCESS,
    payload: { data }
});
