import *  as ActionTypes from "./aspireActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    aspires: []
};



export const aspireReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_ASPIRE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_ASPIRE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_ASPIRE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_ASPIRE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_ASPIRE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_ASPIRE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_ASPIRE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_ASPIRE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_ASPIRE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                aspires: action?.payload?.data?.data || [],
                count: action?.payload?.data?.count || 0
            }
        case ActionTypes.UPDATE_ASPIRE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_ASPIRE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_ASPIRE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}