// import { applyMiddleware } from "redux";
import { configureStore } from '@reduxjs/toolkit';
// import { composeWithDevTools } from "redux-devtools-extension";
import {thunk} from "redux-thunk";
import appReducres from './appReducers';




 

const store = configureStore({
    reducer: appReducres,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    // devTools: process.env.NODE_ENV !== 'production',
});

export default store;