import *  as ActionTypes from "./constituencyActionTypes";

const initialState = {
    isLoading: false,
    isSaved: false,
    constituencys: [],
    count:0,
};



export const constituencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CREATE_CONSTITUENCY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.CREATE_CONSTITUENCY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.CREATE_CONSTITUENCY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.DELETE_CONSTITUENCY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.DELETE_CONSTITUENCY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.DELETE_CONSTITUENCY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        case ActionTypes.GET_CONSTITUENCY:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_CONSTITUENCY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_CONSTITUENCY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                constituencys: action?.payload?.data?.data || [],
                count: action?.payload?.data?.count || 0
            }
        case ActionTypes.UPDATE_CONSTITUENCY:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
            }
        case ActionTypes.UPDATE_CONSTITUENCY_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.UPDATE_CONSTITUENCY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
            }
        default:
            return state

    }
}