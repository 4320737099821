import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, TextField, IconButton, Menu, Avatar, ListItemButton, Badge } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoImg from "../assets/heryana-congress-logo.jpeg"
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CategoryIcon from '@mui/icons-material/Category';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';






const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));




const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        // easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            // easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);





export default function Navbar({ open, setOpen, getAdminById, addminById }) {
    const theme = useTheme();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [masterOpen, setMasterOpen] = useState(true);
    const [userDetail, setUserDetail] = useState(null);



    useEffect(() => {
        const userId = sessionStorage.getItem('adminId');
        if (userId) {
            getAdminById(userId);
        }
    }, [])


    
    


    useEffect(() => {
        setUserDetail(addminById);
    }, [addminById.name])

    const handleClick = () => {
        setMasterOpen(!masterOpen);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleLogOut = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('loggedAdmin');
        window.location.reload();
    }

    const navigate = useNavigate();



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={open}
                sx={{
                    bgcolor: 'white'
                }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon sx={{ color: 'black' }} />
                    </IconButton>


                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >

                        {/* <TextField
                            size='small'
                            placeholder='ctrl + k'
                            sx={{ borderColor: 'white' }}
                        /> */}




                        <Box sx={{ marginLeft: 'auto' }}>
                            


                            


                            <Box sx={{ display: 'flex'}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="black"
                                    sx={{

                                        height: '2.2rem',
                                        bgcolor: 'white',
                                        ml: 1,
                                        borderRadius: 1,
                                        fontSize: '1rem',
                                        fontWeight: '700'
                                    }}
                                >
                                    <Avatar sx={{ width: '2rem', height: '2rem', mr: 1 }} />  {userDetail?.name || 'N/A'}
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', },
                                    }}
                                >

                                    <MenuItem onClick={() => handleLogOut()} >
                                        <Typography textAlign="center">Logout</Typography>
                                    </MenuItem>

                                </Menu>
                            </Box>
                        </Box>



                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader sx={{ bgcolor: '#F7F7F7' }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxHeight: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden'
                    }}>
                        <img
                            src={LogoImg}
                            alt="logo image"
                            style={{
                                maxWidth: '100%',
                                width: '100%'
                            }}

                        />
                    </Box>

                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider />
                <List>

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        onClick={() => navigate('/')}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'}
                            sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>



                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                        onClick={() => navigate('/aspiration-for-list')}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <AssignmentIndIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Aspiration Form List'}
                            sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}

                        onClick={() => navigate('/grievance')}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <AppsOutageIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Grievance Form List'}
                            sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>


                    <ListItemButton
                        onClick={handleClick}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}>
                            <InboxIcon />
                        </ListItemIcon>


                        <ListItemText
                            primary="Master"
                            sx={{ display: open ? 'block' : 'none' }}
                        />
                        {masterOpen ? <ExpandLess sx={{ display: open ? 'block' : 'none' }} /> : <ExpandMore sx={{ display: open ? 'block' : 'none' }} />}
                    </ListItemButton>
                    <Collapse in={masterOpen} timeout="auto" unmountOnExit>

                        <List component="div" disablePadding>

                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/assembly-constituency')}>
                                <ListItemIcon sx={{minWidth:'auto',pr:'2px'}}>
                                    <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary="Assembly Constituency" />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/issue')}>
                                <ListItemIcon sx={{ minWidth: 'auto', pr: '2px' }}>
                                    <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary="Issue" />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/aspiration')}>
                                <ListItemIcon sx={{ minWidth: 'auto', pr: '2px' }}>
                                    <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary="Aspiration " />
                            </ListItemButton>
                        </List>
                    </Collapse>


                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}

                        onClick={() => navigate('/profile')}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Profile'}
                            sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}

                        onClick={() => navigate('/admin-management')}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Admin Management'}
                            sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}

                        onClick={() => navigate('/content')}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <ContentPasteIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Content'}
                            sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}

                        onClick={() => navigate('/content-category')}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Content Category'}
                            sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </List>
            </Drawer>
        </Box >
    );
}
