export const CREATE_CONSTITUENCY = "CREATE_CONSTITUENCY";
export const CREATE_CONSTITUENCY_FAILURE = "CREATE_CONSTITUENCY_FAILURE";
export const CREATE_CONSTITUENCY_SUCCESS = "CREATE_CONSTITUENCY_SUCCESS";

export const DELETE_CONSTITUENCY = "DELETE_CONSTITUENCY";
export const DELETE_CONSTITUENCY_FAILURE = "DELETE_CONSTITUENCY_FAILURE";
export const DELETE_CONSTITUENCY_SUCCESS = "DELETE_CONSTITUENCY_SUCCESS";

export const GET_CONSTITUENCY = "GET_CONSTITUENCY";
export const GET_CONSTITUENCY_FAILURE = "GET_CONSTITUENCY_FAILURE";
export const GET_CONSTITUENCY_SUCCESS = "GET_CONSTITUENCY_SUCCESS";

export const UPDATE_CONSTITUENCY = "UPDATE_CONSTITUENCY";
export const UPDATE_CONSTITUENCY_FAILURE = "UPDATE_CONSTITUENCY_FAILURE";
export const UPDATE_CONSTITUENCY_SUCCESS = "UPDATE_CONSTITUENCY_SUCCESS";
