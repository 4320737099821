export const CREATE_CONTENT_CATEGORY = "CREATE_CONTENT_CATEGORY";
export const CREATE_CONTENT_CATEGORY_FAILURE = "CREATE_CONTENT_CATEGORY_FAILURE";
export const CREATE_CONTENT_CATEGORY_SUCCESS = "CREATE_CONTENT_CATEGORY_SUCCESS";

export const GET_CONTENT_CATEGORY = "GET_CONTENT_CATEGORY";
export const GET_CONTENT_CATEGORY_FAILURE = "GET_CONTENT_CATEGORY_FAILURE";
export const GET_CONTENT_CATEGORY_SUCCESS = "GET_CONTENT_CATEGORY_SUCCESS";

export const UPDATE_CONTENT_CATEGORY = "UPDATE_CONTENT_CATEGORY";
export const UPDATE_CONTENT_CATEGORY_FAILURE = "UPDATE_CONTENT_CATEGORY_FAILURE";
export const UPDATE_CONTENT_CATEGORY_SUCCESS = "UPDATE_CONTENT_CATEGORY_SUCCESS";

export const DELETE_CONTENT_CATEGORY = "DELETE_CONTENT_CATEGORY";
export const DELETE_CONTENT_CATEGORY_FAILURE = "DELETE_CONTENT_CATEGORY_FAILURE";
export const DELETE_CONTENT_CATEGORY_SUCCESS = "DELETE_CONTENT_CATEGORY_SUCCESS";



 



