export const CREATE_ISSUE = "CREATE_ISSUE";
export const CREATE_ISSUE_FAILURE = "CREATE_ISSUE_FAILURE";
export const CREATE_ISSUE_SUCCESS = "CREATE_ISSUE_SUCCESS";

export const DELETE_ISSUE = "DELETE_ISSUE";
export const DELETE_ISSUE_FAILURE = "DELETE_ISSUE_FAILURE";
export const DELETE_ISSUE_SUCCESS = "DELETE_ISSUE_SUCCESS";

export const GET_ISSUE = "GET_ISSUE";
export const GET_ISSUE_FAILURE = "GET_ISSUE_FAILURE";
export const GET_ISSUE_SUCCESS = "GET_ISSUE_SUCCESS";

export const UPDATE_ISSUE = "UPDATE_ISSUE";
export const UPDATE_ISSUE_FAILURE = "UPDATE_ISSUE_FAILURE";
export const UPDATE_ISSUE_SUCCESS = "UPDATE_ISSUE_SUCCESS";
