import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
 

const LoginContainer = lazy(() => import('./pages/login/login.container'));
const DashboardContainer = lazy(() => import('./pages/dashboard/dashbord.container'));
const AspireContainer = lazy(() => import('./pages/masters/aspire/aspire.container'));
const ForgotPasswordContainer = lazy(() => import('./pages/forgot password/forgotPassword.container'));
const ContentContainer = lazy(() => import('./pages/content/content.container'));
const ContentCategoryContainer = lazy(() => import('./pages/content-category/content-category.container'));
const AspirationContainer = lazy(() => import('./pages/masters/aspire/aspire.container'));
const AssemblyContainer = lazy(() => import('./pages/masters/assembly-constituency/assembly-constituency.container'));
const IssueContainer = lazy(() => import('./pages/masters/isuue/isuue.container'));
const GrievanceContainer = lazy(() => import('./pages/grievance/grievance.container'));
const AspirationForListContainer = lazy(() => import('./pages/aspirationForList/aspiration.container'));
const ProfileContainer = lazy(() => import('./pages/profile/profile.container'));
const AdminManagementContainer = lazy(() => import('./pages/admin-management/admin-management.container'));



const RoutePath = () => {
    return (
        <Routes>
            <Route path="/login" element={<Suspense fallback={<>...</>}><LoginContainer /></Suspense>} />
            <Route path="/forgot-password" element={<Suspense fallback={<>...</>}><ForgotPasswordContainer /></Suspense>} />
            <Route path="/" element={<Suspense fallback={<>...</>}><DashboardContainer /></Suspense>} />
            <Route path="/aspire" element={<Suspense fallback={<>...</>}><AspireContainer /></Suspense>} />
            <Route path="/content" element={<Suspense fallback={<>...</>}><ContentContainer /></Suspense>} />
            <Route path="/content-category" element={<Suspense fallback={<>...</>}><ContentCategoryContainer /></Suspense>} />
            <Route path="/aspiration" element={<Suspense fallback={<>...</>}><AspirationContainer /></Suspense>} />
            <Route path="/assembly-constituency" element={<Suspense fallback={<>...</>}><AssemblyContainer /></Suspense>} />
            <Route path="/issue" element={<Suspense fallback={<>...</>}><IssueContainer /></Suspense>} />
            <Route path="/grievance" element={<Suspense fallback={<>...</>}><GrievanceContainer /></Suspense>} />
            <Route path="/aspiration-for-list" element={<Suspense fallback={<>...</>}><AspirationForListContainer /></Suspense>} />
            <Route path="/profile" element={<Suspense fallback={<>...</>}><ProfileContainer /></Suspense>} />
            <Route path="/admin-management" element={<Suspense fallback={<>...</>}><AdminManagementContainer /></Suspense>} />
        </Routes>
    )
}

export default RoutePath;