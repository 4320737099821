export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";


export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";


export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_FAILURE = "UPDATE_ADMIN_FAILURE";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";

export const GET_ADMIN_BY_ID = "GET_ADMIN_BY_ID";
export const GET_ADMIN_BY_ID_FAILURE = "GET_ADMIN_BY_ID_FAILURE";
export const GET_ADMIN_BY_ID_SUCCESS = "GET_ADMIN_BY_ID_SUCCESS";


export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";


export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_FAILURE = "CREATE_ADMIN_FAILURE";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";




