import { combineReducers } from "redux";
import { adminReducer as adminPage } from '../api/admin/adminReducer'
import { constituencyReducer as constituencyPage } from "../api/constituency/constituencyReducer";
import { issueReducer as issuePage } from "../api/issue/issueReducer";
import { aspirationReducer as aspirationPage } from "../api/aspiration/aspirationReducer";
import { aspireReducer as aspirePage } from "../api/aspire/aspireReducer";
import { grievancesReducer as grievancesPage } from "../api/grievances/grievancesReducer";
import { contentReducer as contentPage } from "../api/content/contentReducer";
import { contentCategoryReducer as contentCategoryPage } from "../api/content-category/contentCategoryReducer";



const appReducres = combineReducers({
    adminPage,
    constituencyPage,
    issuePage,
    aspirationPage,
    aspirePage,
    grievancesPage,
    contentPage,
    contentCategoryPage
})


export default appReducres;