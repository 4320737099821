// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Customize the primary color
        },
        secondary: {
            main: '#dc004e', // Customize the secondary color
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
            fontSize: '2rem',
        },
        h2: {
            fontSize: '1.75rem',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1976d2', // Border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1976d2', // Border color on focus/click
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1976d2', // Border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1976d2', // Border color on focus/click
                    },
                },
            },
        },
    },
});

export default theme;
