import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Navbar from '../common/navbar';
import { useNavigate, useLocation } from 'react-router-dom';
import RoutePath from '../routePath';
import { styled } from '@mui/material/styles';






const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, isLoggedIn }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: isLoggedIn ? '4rem' : 0,
        marginTop: 0,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`,
        }),
    }),
);


const RootComponent = (props) => {
    const { isLoggedIn, getAdminById, addminById } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();




    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login');
        }
    })
 

    
    useEffect(() => {
        if (isLoggedIn) {
          setOpen(true)
        }
    },[isLoggedIn])
 


    return (
        <Box bgcolor={'#FAFAFB'}>
            {isLoggedIn ?
                (<Navbar
                    setOpen={setOpen}
                    open={open}
                    getAdminById={getAdminById}
                    addminById={addminById}
                />)
                : null}
            <Box flexGrow={1}   >
                <Box sx={{ pt: isLoggedIn ? 2 : 0 }} >
                    <Main open={open} isLoggedIn={isLoggedIn}>
                        <RoutePath />
                    </Main>
                </Box>
            </Box>
        </Box>
    )
}

export default RootComponent