export const CREATE_GRIEVANCES = "CREATE_GRIEVANCES";
export const CREATE_GRIEVANCES_FAILURE = "CREATE_GRIEVANCES_FAILURE";
export const CREATE_GRIEVANCES_SUCCESS = "CREATE_GRIEVANCES_SUCCESS";

export const DELETE_GRIEVANCES = "DELETE_GRIEVANCES";
export const DELETE_GRIEVANCES_FAILURE = "DELETE_GRIEVANCES_FAILURE";
export const DELETE_GRIEVANCES_SUCCESS = "DELETE_GRIEVANCES_SUCCESS";

export const GET_GRIEVANCES = "GET_GRIEVANCES";
export const GET_GRIEVANCES_FAILURE = "GET_GRIEVANCES_FAILURE";
export const GET_GRIEVANCES_SUCCESS = "GET_GRIEVANCES_SUCCESS";


export const GET_GRIEVANCES_BY_ID = "GET_GRIEVANCES_BY_ID";
export const GET_GRIEVANCES_BY_ID_FAILURE = "GET_GRIEVANCES_BY_ID_FAILURE";
export const GET_GRIEVANCES_BY_ID_SUCCESS = "GET_GRIEVANCES_BY_ID_SUCCESS";

export const UPDATE_GRIEVANCES = "UPDATE_GRIEVANCES";
export const UPDATE_GRIEVANCES_FAILURE = "UPDATE_GRIEVANCES_FAILURE";
export const UPDATE_GRIEVANCES_SUCCESS = "UPDATE_GRIEVANCES_SUCCESS";
